import {
	type LoaderFunctionArgs,
	json,
	type LinksFunction,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useParams,
} from '@remix-run/react'
import { useEffect } from 'react'
import {
	CustomToaster,
	toastError,
	toastSuccess,
} from '#app/components/custom-toaster'
import { DEFAULT_LOCALE } from '#app/data/config'
import { APP_TARGET, SITE_NAME, TWA_PACKAGE_NAME } from '#app/env.remix/config'
import { setDisplayMode } from '#app/hooks/use-display-mode'
import { requestToSession, sessionStorage } from '#app/utils/session.server'
import { toastMessageSchema } from '#app/utils/zod.server'
import { GeneralErrorBoundary } from './components/error-boundary'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { useNonce } from './utils/nonce-provider'

export let links: LinksFunction = () => {
	return [
		{
			rel: 'apple-touch-icon',
			sizes: '180x180',
			href: '/icons/apple-touch-icon-180x180.png',
		},
		{ rel: 'icon', href: '/favicon.ico' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	]
}

// title: SITE_NAME2,
export async function loader({ request }: LoaderFunctionArgs) {
	let session = await requestToSession(request)
	let safeToastMessage = toastMessageSchema.safeParse(
		session.get('toastMessage'),
	)

	return json(
		{
			toastMessage: safeToastMessage.success ? safeToastMessage.data : null,
		},
		{
			headers: {
				'Set-Cookie': await sessionStorage.commitSession(session),
			},
		},
	)
}

function Document({
	children,
	nonce,
}: {
	children: React.ReactNode
	nonce: string
}) {
	let { locale } = useParams()

	return (
		<html lang={locale || 'en'}>
			<head>
				<link rel="manifest" href={`/manifest-${locale || 'en'}.json`} />
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1,viewport-fit=cover"
				/>
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="default" />
				<meta name="apple-mobile-web-app-title" content={SITE_NAME} />
				<meta name="application-name" content={SITE_NAME} />
				<meta name="format-detection" content="telephone=no" />
				<meta name="mobile-web-app-capable" content="yes" />
				<meta name="theme-color" content="#A9ADC1" />
				<Meta />
				<Links />
				{APP_TARGET === 'NEO' ? (
					<script
						defer
						src="https://x.zenliving.vip/script.js"
						data-website-id="4aa7ee71-ef87-4833-bed9-de1b4b6dbcf4"
					/>
				) : APP_TARGET === 'TR' ? (
					<script
						defer
						src="https://x.zenliving.vip/script.js"
						data-website-id="17267277-75b1-4efc-88d3-8ff411762c31"
					/>
				) : APP_TARGET === 'PR' ? (
					<script
						defer
						src="https://x.zenliving.vip/script.js"
						data-website-id="1ea4671b-533d-4c44-ab8c-8f3bab05822d"
					/>
				) : null}
			</head>
			<body>
				{children}
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

export default function App() {
	let data = useLoaderData<typeof loader>()
	let nonce = useNonce()

	useEffect(() => {
		setDisplayMode(TWA_PACKAGE_NAME)
	}, [])

	useEffect(() => {
		if (data.toastMessage) {
			switch (data.toastMessage.type) {
				case 'error':
					toastError(data.toastMessage.message)
					break

				case 'success':
					toastSuccess(data.toastMessage.message)
					break
			}
		}
	}, [data.toastMessage])

	return (
		<Document nonce={nonce}>
			<Outlet />
			<CustomToaster />
		</Document>
	)
}

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	let nonce = useNonce()

	// return (
	// 	<Document nonce={nonce}>
	// 		<GeneralErrorBoundary />
	// 	</Document>
	// )
	let params = useParams()
	let locale = params.locale || DEFAULT_LOCALE

	return (
		<Document nonce={nonce}>
			<div className="flex min-h-screen flex-col">
				<main
					style={{
						backgroundImage:
							'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
					}}
					className="flex-grow bg-cover bg-top sm:bg-top"
				>
					<div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
						<p className="text-sm font-semibold uppercase tracking-wide text-black text-opacity-50">
							404 error
						</p>
						<h1 className="mt-2 text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
							Uh oh! I think you’re lost.
						</h1>
						<p className="mt-2 text-lg font-medium text-black text-opacity-50">
							{`It looks like the page you’re looking for doesn't exist.`}
						</p>
						<div className="mt-6">
							<a
								href={`/${locale}`}
								className="inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-black text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
							>
								Go back home
							</a>
						</div>
					</div>
				</main>
			</div>
		</Document>
	)
}
